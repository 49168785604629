$cover-image-aspect-ratio: 2.36;

// Import Bootstrap for Sass
@import "bootstrap_overwrites";
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";
@import "bootstrap/scss/grid";

$dashboard-header-bg: $gray-lightest;

@import "src/scss/material.scss";
@import '~simplemde/dist/simplemde.min.css';

@import '~dragula/dist/dragula.css';

@import "modals";
//@import "~ngx-toastr/toastr-bs4-alert";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "forms";
@import "sidenav";
@import "investor";
@import "icons";

@import "app-loader";

@font-face {
  font-family: "Avenir";
  src: url("/fonts/avenir-book.eot");
  src: url("/fonts/avenir-book.eot?#iefix") format("embedded-opentype"),
  url("/fonts/avenir-book.woff") format("woff"),
  url("/fonts/avenir-book.ttf") format("truetype"),
  url("/fonts/avenir-book.svg#avenir-book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DM Serif Display";
  src: url("/fonts/DMSerifDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Light";
  src: url("/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  min-width: 320px;
}

.purple-background {
  background-color: $primary;
  color: white;
}

.purple-light-background {
  background-color: #D19CCD;
  color: white;
}

.blue-background {
  background-color: #A6C6F6;
  color: white;
}

.green-background {
  background-color: #5AF6CB;
  color: white;
}

.red-background {
  background-color: $red;
  color: white;
}

.gray-background {
  background-color: $gray-darker;
  color: white;
}

.extendWidth {
  ng-select {
     .ng-dropdown-panel {
      margin-left: -15%;
      width: 130%;
      max-width: 550px;
    }
  }
}

.dashboard-header {
  .navbar-toggle {
    padding: 1.4rem 1.2rem;
    display: inline-block;
    text-align: center;
    width: $sidenav-collapsed-width;
    height: $header-height;
    color: $secondary;
    font-size: 24px;
    vertical-align: middle;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  background: $dashboard-header-bg;
  height: $header-height;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;

  .logo-bar {
    width: $sidenav-width;
    height: $header-height + 40px;
    background: white;
    margin-right: auto;

    .navbar-brand {
      padding: 21px 10px;

      img {
        margin-left: 20px;
        height: 80px;
      }
    }
  }

  .notifications, .balance {
    margin: 1rem;
    opacity: 0.5;
  }

  .notifications {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i, fa-icon {
      font-size: 1.7em;
    }
  }

  .balance {
    padding-left: 2rem;
    position: relative;
    font-family: Arial, Helvetica, SansSerif;

    .fa-euro {
      position: absolute;
      line-height: 40px;
      font-size: 30px;
      left: 5px;
    }
  }

  .header-actions {
    display: table;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;

    .action {
      display: table-cell;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .popover {
    margin-top: 0.8rem;
  }

  .popover-body {
    font-size: $font-size-base;
  }

  .account-info {
    padding: 0.3rem;

    .name {
      font-size: 20px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0.5rem 0 0 0;

      li {
        min-width: 240px;

        a {
          display: block;
          padding: 1rem 0;
          color: $body-color;

          &:hover, &:active {
            text-decoration: none;
            color: $primary;
          }

          i, fa-icon {
            margin-right: 1rem;
            font-size: 16px
          }

          &.active {
            font-weight: bold;

            i.fa-folder {
              &:before {
                content: "\f07c";
              }
            }
          }
        }

        &.top {
          border-bottom: 1px solid $gray-lighter;
        }

        &.bottom {
          //border-top: 1px solid $gray-lighter;
        }
      }
    }
  }

  .arrow {
    right: 1.3em !important;
  }
}

.main-container {
  min-height: calc(100vh - 80px); //$header-height
  background-color: #fff;

  @media (max-width: 1199px) {
    margin-left: 0;
  }
}

.inner-container {
  margin-top: $header-height;
  padding: ($inner-spacing + 0.5rem) $inner-spacing $inner-spacing $inner-spacing;
  min-height: calc(100vh - 80px);
}

.inner-container-without-padding {
  padding-top: $header-height;
  background-color: white;
}

.inner-container-sidenav-padding {
  padding-top: $header-height;
  padding-left: $sidenav-width;
  background-color: white;
}

.inner-container.with-sidenav {
  margin-left: $sidenav-width;
  background: $gray-lighter;

  .in-header-title {
    height: $header-height;
    line-height: $header-height;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: $inner-spacing;
    position: fixed;
    top: 0;
    z-index: 1100;

    i, fa-icon {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .profile-prompt {
    background: $secondary;
    color: white;
    align-items: center;
    padding: 1.5rem;
    margin: -$inner-spacing;
    margin-bottom: $inner-spacing;

    img {
      margin-top: -6px;
    }

    h2 {
      margin: 0;
    }

    a {
      color: white;
    }
  }

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: $body-color;
  }

  .page-title {
    display: flex;
    padding-bottom: 1rem;
    text-transform: uppercase;

    .title {
      flex: 1;
      color: darken($body-color, 15%);
      font-size: 20px;
      line-height: 1.7;
      padding-right: 15px;
    }

    .actions {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;

      @media screen and (max-width: 567px) {
        display: block;
        li {
          margin: 5px 0;
        }
      }

      li + li {
        margin-left: 0.5rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: $inner-spacing-mobile;

    .profile-prompt {
      margin: -$inner-spacing-mobile;
      margin-bottom: $inner-spacing-mobile;
      padding: 1rem;

      img {
        margin-bottom: 3px;
      }
    }
  }
}

.breadcrumb {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  background-color: unset;
  padding: unset;

  @include media-breakpoint-up(md) {
    margin-top: -1.2rem;
  }
}

.pre-auth-content {
  padding-top: $header-height;

  .container-fluid {
    min-height: 100vh;
  }

  @media (max-width: 767px) {
    padding-top: 20px;
  }
}

.remove-top-padding {
  margin-top: -$header-height;
  padding-top: $header-height;
}

.pre-auth-block {
  width: 100%;
  max-width: 620px !important;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    color: $secondary;
    text-transform: uppercase;

    .small {
      font-size: 80%;
      line-height: 1.6;
    }
  }

  h2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
  }

  form {
    max-width: 320px;
    width: 100%;
    margin: auto;

    .btn-primary, button.mat-primary {
      display: block;
      width: 100%;
      max-width: 320px;
      margin-top: 2rem;
    }

    .mtn {
      margin-top: -0.6rem;
    }
  }

  .shadow-field, .shadow-btn {
    max-width: 320px;
  }

  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 1.3rem;
    }
  }
}

.profile-block {
  form {
    max-width: 800px;
    width: 100%;
    margin: auto;
  }
}

.detail-block {
  max-width: 800px;
}

.table {
  thead th {
    text-transform: uppercase;
    border-top: none;
    font-size: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 1px;
    vertical-align: middle;
    border: none;
  }

  tfoot th {
    text-transform: uppercase;
    border-top: none;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 1px;
    vertical-align: middle;
    border: none;
  }

  tbody tr:first-child td {
    border-top: none;
  }

  &.rounded-header {
    thead th {
      border: none;

      &:first-child {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
      }

      &:last-child {
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
      }
    }
  }

  .thead-lighter {
    th {
      background: #f7f7f7;
    }
  }
}

.sandbox-header {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.card {
  margin-bottom: 1.2rem;
  background-color: white;

  &.full-height {
    height: calc(100% - 1.2rem);
  }

  &.card-disable {
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    //filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    opacity: 0.5
  }

  @include media-breakpoint-up(md) {
    .card-body {
      padding: 1.5rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: $inner-spacing-mobile;
  }
}

.responsive-swipetip {
  float: right;
  color: $primary;

  i + i {
    margin-left: 0.5rem;
  }

  fa-icon + fa-icon {
    margin-left: 0.5rem;
  }

  fa-icon + fa-icon {
    margin-left: 0.5rem;
  }

  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.table-responsive {
  table, .mat-table, mat-table, .table {
  }
}

.btn {
  text-transform: uppercase;

  &:not(.btn-sm) {
    font-size: 0.875rem;
  }

  &.btn-default {
    background-color: $gray-light;

    &:hover {
      background-color: darken($gray-light, 10%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.25);
    }
  }

  &.btn-outline-dark {
    color: #888888;
    border-color: #888888;

    &:hover, &:focus {
      background-color: #888888;
      color: white;
    }
  }

  &.btn-disabled, &:disabled, &[disabled] {
    cursor: auto;
    opacity: 0.5 !important;
  }

  .icon, .fa {
    margin-right: 0.5rem;
  }

  &.btn-white-primary, &.btn-white-default {
    background: white;

    &:hover, &:focus {
      background: darken(white, 5%);
    }
  }

  &.btn-white-primary {
    color: $primary;
  }

  &.btn-outline-danger {
    color: $danger;

    &:not([disabled]) {
      &:hover, &:focus {
        color: white;
      }
    }
  }

  &.btn-light {
    color: $gray-darker;
    background-color: transparent;
    border: none;

    &:hover {
      color: $primary;
    }

    &:active {
      color: $primary;
      background-color: white !important;
    }
  }

  @include media-breakpoint-down(xs) {
    &.btn-block-mobile {
      width: 100%;
      margin: 0 0 1rem 0;
    }
  }
  /*@include media-breakpoint-down(sm) {
    &.btn-lg {
      padding: $input-btn-padding-y $input-btn-padding-x;
    }
  }*/

  &.btn-float-right {
    float: right;
  }
}

strong {
  font-weight: 700;
}

strong.semi {
  font-weight: 500;
}

h3 {
  font-weight: 500;
}

$container-size-sm: 450px;
$container-size-md: 900px;
$container-size-lg: 1280px;

.container-sm {
  max-width: $container-size-sm;
}

.container-md {
  max-width: $container-size-md;
}

.container-lg {
  max-width: $container-size-lg;
}


//Dashboard

app-profile-pic {
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 100%;
  display: block;

  &.profile-pic-xs {
    width: 25px;
    height: 25px;
    line-height: 26px;
    font-size: 11px;
  }

  &.profile-pic-sm {
    width: 45px;
    height: 45px;
    line-height: 46px;
    font-size: 18px;
  }

  &.profile-pic-md {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 40px;
  }

  &.profile-pic-ml {
    width: 160px;
    height: 160px;
    line-height: 160px;
    font-size: 64px;
  }

  &.profile-pic-lg {
    width: 200px;
    height: 200px;
    line-height: 200px;
    font-size: 80px;
  }
}


.profile-edit-pic-wrap {
  position: relative;
  width: 200px;

  &:hover .update-profile-pic {
    opacity: 1;
  }

  .update-profile-pic {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    height: 100px;
    border-radius: 0 0 100px 100px;
    white-space: normal;
    line-height: 1.4;
    left: 4px;
    bottom: 4px;
    width: 192px;
    text-transform: none;
    opacity: 0;
    transition: opacity 0.2s;

    i, fa-icon {
      font-size: 20px;
    }
  }
}

.portfolio-value {
  white-space: nowrap;

  .topline {
    font-weight: 700;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }

  .amount {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
  }
}

.project-tokens-header {
  white-space: nowrap;
  display: inline-block;
  line-height: normal;

  .topline {
    font-weight: 700;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }

  .amount {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
  }

  .target {
    color: $secondary;
    font-size: 20px;
    font-weight: bold;
  }

  .availability-chart {
    position: absolute;
    display: inline-block;
    // The ngx-charts control includes a 20px margin already, so we don't need to add extra...
    left: 0;
    top: -12px;
    width: 95px;
    height: 95px;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 7px;
      padding: 0.5rem;

      .percentage-label {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

.overview-wrap {
  @include media-breakpoint-up(lg) {
    display: flex;

    .col-table {
      flex: 1 1 0%;
      padding-left: 1rem;
    }
  }

  @include media-breakpoint-down(md) {
    .col-chart {
      margin-bottom: 1rem;
    }
  }
}

.progress-bar-wrap {
  display: flex;
  align-items: center;
  max-width: 200px;
  min-width: 110px;
  width: 100%;

  .progress {
    flex: 3;
  }

  .progress-bar-value {
    flex: 1;
    margin-left: 1rem;
    font-family: Arial, Helvetica, SansSerif;
    font-weight: 700;
    color: $primary;
    min-width: 37px;
    text-align: right;
  }
}

.upload-item {
  .progress {
    height: 2rem;
  }
}

.progress-bar {
  border-radius: $progress-border-radius;
}

.assets-chart {
  width: 290px;
  margin-left: -20px;
  height: 290px;
  position: relative;
  display: inline-block;

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 145px;
    height: 145px;
    border-radius: 100%;
    background: white;
    border: solid 4px $gray-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 0.5rem;

    .percent {
      color: $primary;
      font-weight: 700;
      font-size: 32px;
    }
  }
}

.shadow-empty-chart {
  width: 250px;
  height: 250px;
  background-color: $gray-lighter;
  border-radius: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  .empty-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 145px;
    height: 145px;
    border-radius: 100%;
    background: white;
    border: solid 4px $gray-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.project-blocks {
  .card {
    //max-width: 620px;
    margin-left: auto;
    margin-right: auto;

    .card-img-holder {
      width: 100%;
      height: 0;
      padding-bottom: 42.1%;
      background: $gray-darker;
    }

    .btn {
      font-size: 14px;
    }

    .card-title {
      font-weight: 400;
      font-size: 2em;
      color: $primary;
    }

    .sub-title {
      font-style: italic;
      margin-top: -0.8em;
    }

    .card-body {
      position: relative;
      padding: 1rem;
    }
  }

  .btn {
    .less {
      display: inline;
    }

    .more {
      display: none;
    }

    &.collapsed {
      .less {
        display: none;
      }

      .more {
        display: inline;
      }
    }
  }

  @media (min-width: 768px) {
    .card .btn {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .card-title {
      padding-right: 180px;
    }
  }

  @media (max-width: 767px) {
    .card .btn {
      display: block;
    }
  }

  .project-stats {
    flex-wrap: wrap;
    //margin: 0 -1rem -1rem -1rem;
    li {
      flex: 1;
      padding: 1rem;
    }
  }
}

.project-detail {
  width: 100%;

  .project-banner {
    position: relative;
    color: white;
    margin-bottom: 1.5rem;

    .banner-img-holder {
      width: 100%;
      height: 0;
      padding-bottom: 42%;
      background: $gray-darker;
    }

    .banner-img {
      width: 100%;
    }

    .img-overlay {
      .title {
        font-weight: 400;
        font-size: 2em;
      }

      .btn-wrapper {
        float: right;
        margin-bottom: 2rem;
        margin-right: 2rem;

        img {
          height: 20px;
          margin-top: -1px;
        }
      }

      .btn-sm {
        min-height: 2.6rem;
        min-width: 11.5rem;
      }
    }

    .project-stats {
      color: white;
      background: rgba(0,0,0,0.4);
    }

    @include media-breakpoint-up(lg) {
      .img-overlay {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
      }
    }

    @include media-breakpoint-down(md) {
      .img-overlay {
        position: relative;

        .btn-wrapper {
          position: absolute;
          right: 0;
          top: -80px;
        }

        .btn.disabled, .btn:disabled {
          opacity: inherit;
        }
      }

      .project-stats {
        background: black;
      }
    }

    @include media-breakpoint-down(sm) {
      .img-overlay {
        display: flex;
        flex-direction: column;
        background: black;

        .btn-wrapper {
          position: static;
          margin: 1rem;
          float: none;
          top: auto;
          right: auto;
          order: 1;

          .btn {
            display: block;
            width: 100%;
          }
        }

        .project-stats {
          border-bottom: 1px solid $gray-light;
        }
      }
    }
  }
}

.project-stats {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-content: center;
  border-bottom: 1px solid $gray-lighter;

  li {
    display: block;
    padding: 1rem;
    font-size: 0.75rem;
    border-top: 1px solid $gray-lighter;

    + li {
      border-left: 1px solid $gray-lighter;
    }

    .badge {
      font-size: 90%;
    }

    &.project-title {
      flex: auto;

      + li {
        border-left: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;

    li {
      flex: 1
    }

    li.project-title {
      width: 100%;
    }
  }
}


.toast-container {
  width: auto;
  min-width: 500px;
  right: auto;
  left: 50%;
  transform: translateX(-50%);

  .toast {
    width: 100% !important;
    padding: 1.2rem 1.25rem .65rem 50px;
    background-size: 20px;

    .toast-title {
      display: none;
    }

    .toast-close-button {
      border: none;
      top: -1px;
      background: transparent;
      font-size: 30px;
      line-height: 0.8;
      font-weight: normal;
      padding: 0;
      outline: none !important;
    }

    &.toast-warning .toast-close-button:hover {
      color: orange;
    }

    &.toast-info .toast-close-button:hover {
      color: lightgray;
    }

    &.toast-success .toast-close-button:hover {
      color: forestgreen;
    }

    &.toast-error .toast-close-button:hover {
      color: red;
    }
  }
}


@include media-breakpoint-down(md) {
  .toast-container {
    min-width: 0;
    transform: none;
    left: 0.5rem;
    right: 0.5rem;
  }
}

/* Table */

.filter-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: $inner-spacing/2;
  padding-bottom: 0;
  background: white;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 12px;

  .search-fields {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $inner-spacing/2;
    align-items: center;

    .form-control {
      min-width: 280px;
    }

    > * + * {
      margin-left: 0.75em;
    }
  }

  .clear-fields {
    display: flex;
    padding-bottom: $inner-spacing/2;
    align-items: center;
  }

  .form-control {
    width: auto;
    background: transparent;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .btn-white-default, .btn-white-primary {
    background: transparent;

    &:hover, &:active, &:focus {
      background: rgba(0,0,0,0.05);
    }
  }

  label {
    margin-bottom: 0;
    min-height: unset;
  }
}

label.mat-checkbox-layout {
  margin-top: 0;
}

.card.table-content {
  .table-responsive {
    height: 100%;
  }
}

.mat-header-row {
  min-height: 0;

  .mat-header-cell {
    color: darkgrey;
    text-transform: uppercase;
    font-weight: 700;
    padding: $table-cell-padding-sm;
  }

  button, .btn {
    text-transform: uppercase;
  }
}

.mat-table {
  tr.mat-header-row {
    height: auto;
    min-height: 38px;
  }
}

.mat-row {
  min-height: 0;

  &.has-link {
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: $gray-lightest;

      td:first-child {
        position: relative;

        &:before {
          content: '';
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent $primary;
          position: absolute;
          left: 0;
          top: calc(50% - 5px);
          transition: left 0.1s;
        }
      }
    }

    .mat-chip-list-wrapper {
      display: inline;
      margin-left: 5px;
    }
  }

  .mat-cell {
    padding: $table-cell-padding-sm;
    color: $body-color;

    &.remove-link {
      cursor: default;
    }

    fa-icon {
      cursor: pointer
    }
  }

  td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
    padding-right: 24px !important;
  }
}

.mat-select-panel-wrap {
  flex-basis: auto !important;
}

.text-right .mat-sort-header-container {
  flex-flow: row-reverse;
}

.mat-cell, .mat-header-cell {
  &.right {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

.mat-cell, .mat-header-cell {
  &.center {
    display: flex !important;
    justify-content: center !important;
  }
}

.mat-table-no-results {
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: white;
}

.no-results {
  text-align: center;
  font-weight: bold;
}

.mat-table {
  font-family: $font-family-base;

  i {
    font-size: 1rem;
  }

  i.fa-minus {
    color: $gray-light;
    font-size: 0.87rem;
  }

  i.fa-check {
    color: $primary;
  }

  .column-narrow {
    flex: 0.7;
  }

  .column-narrower {
    flex: 0.5;
  }

  .column-narrowest {
    flex: 0.05;
  }

  .column-wider {
    flex: 1.5;
  }

  .column-widest {
    flex: 2;
  }

  .form-check {
    margin: 0;

    .form-check-label {
      height: 21px;
      padding-left: 21px;
      transform: scale(0.7);
    }
  }

  a:hover {
    text-decoration: none;
  }

  .mat-header-row {
    background: $table-head-bg;
    border-bottom: none;
  }
}

.mat-row:last-child {
  border: none
}

.spinner-centre {
  margin: 0 auto;
}

.table-filter-layout {
  .table-content {
    width: 100%;
  }

  .wide-screen-filter {
    display: none;
  }

  @media (min-width: 10px) {
    display: flex;

    .filter-wrap {
      order: 1;
      max-width: 280px;
      width: 100%;
      margin-left: $inner-spacing;
      flex-direction: column;
      justify-content: flex-start;

      .search-fields {
        flex-direction: column;
        width: 100%;

        .form-control {
          min-width: 0;
          width: 100%;
        }

        .mat-form-field {
          width: 100%;
        }

        > * + * {
          margin: 0;
          width: 100%;
        }
      }

      .wide-screen-filter {
        display: flex;
        flex-direction: column;
      }

      .clear-fields {
        padding-top: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;
        justify-content: space-between;
      }

      .form-control {
        margin-bottom: 0.5rem;
      }

      .search-btn {
        margin-top: 1rem;
      }

      .filter-toggle {
        display: none;
      }

      button.mat-primary {
        margin-top: 2rem !important;
      }

      button.mat-accent {
        margin-top: 0.5rem !important;
      }
    }
  }
}

.user-detail {
  .profile-pic-wrap {
    position: relative;
    display: inline-block;

    .status {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      right: 6px;
      bottom: 6px;
      background: $gray-darker;
      text-align: center;

      i, fa-icon {
        font-size: 26px;
        line-height: 42px;
        color: white;
      }

      &.active {
        background: #569c56;
      }
    }
  }

  .user-name {
    font-weight: 500;
    margin-top: 1rem;
    font-size: 1rem;
  }
}

.user-detail-view {
  display: flex;
  width: 100%;

  .user-detail {
    flex: 1;

    .user-name {
      margin-top: 2rem;
      font-size: 2rem;
    }
  }

  .user-side-column {
    width: 280px;
    margin-left: $inner-spacing;

    .card-body {
      padding: 1rem;
    }
  }
}


.company-detail-view {
  display: flex;
  width: 100%;
  min-height: 200px;
  margin-bottom: 2rem;

  .company-detail {
    flex: 1;
  }

  .company-side-column {
    width: 280px;
    margin-left: $inner-spacing;

    .card-body {
      padding: 1rem;
    }
  }

  .company-name {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: 500;
  }

  .user-email {
  }

  .user-settings {
  }
}

.cover-image-container {
  position: relative;
  width: 100%;

  &:before {
    content: "";
    display: block;
  }

  .cover-image {
    top: 0;
    width: 100%;
    height: auto;
    filter: grayscale(0%);
  }

  .cover-image-backdrop {
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: lightgray;

    .content {
      margin: auto;

      i, fa-icon {
        font-size: 40px;
      }
    }
  }
}

.btn-upload-cover {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.image-row .image {
  flex: 1;
  min-width: 300px;
  max-width: 750px;
  overflow: hidden;
  border: 5px solid white;
}

.image-row .image .img-wrap {
  position: relative;
  border: 1px solid #979797;
  height: 0;
  padding-bottom: 53%;
  background-size: cover;
  background-color: #808080;
  background-repeat: no-repeat;
  background-position: center center;
}

.image-row .image[data-toggle] {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.image-row .image[data-toggle]:hover {
  z-index: 10;
  transform: scale(1.02);
}

@media (max-width: 575.98px) {
  .image-row .image {
    min-width: 240px;
  }
}

.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.img-card {
  position: relative;
  background-color: lightgray;
  margin: 10px;
  transition: all 0.3s;
  filter: grayscale(0%);

  img {
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1);
  }

  i, fa-icon {
    color: white;
    font-size: 30px;
  }

  &.add {
    width: 180px;
    height: 177px;
    background: lightgray;
    cursor: pointer;

    fa-icon {
      width: 40px;
      height: 40px;
      position: absolute;
      text-align: center;
      line-height: 54px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-in-out;

      i, fa-icon {
        color: $body-color;
        transition: all 0.2s ease-in-out;
      }
    }

    &:hover {
      background: darken(lightgray, 10%);

      i, fa-icon {
        transform: scale(1.3);
        transform-origin: center;
      }
    }
  }
}

@media (hover: none) {
  .dragable-actions {
    opacity: 1 !important;
  }
}

.dragable {
  transition: all 0.3s;
  cursor: pointer;

  .drag-overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(50, 50, 50, 0.3);
    transition: all 0.2s;
    z-index: 1;

    .drag-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      i, fa-icon {
        color: white;
        font-size: 30px;
      }
    }
  }

  .dragable-actions {
    list-style: none;
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    opacity: 0;

    li {
      display: block;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 45px;
      transition: all 0.2s;
      z-index: 2;

      i, fa-icon {
        font-size: 20px;
        color: white;
      }

      + li {
        margin-left: 10px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }

  &:hover {
    .drag-overlay,
    .dragable-actions {
      opacity: 1;
    }
  }

  &.gu-mirror {
    cursor: move;
    transform: scale(1.07);

    .drag-icon {
      display: block;
    }

    .dragable-actions {
      display: none;
    }
  }
}


.markdown {
  li p {
    margin-bottom: 0 !important
  }
}

.hidden {
  display: none;
}

.steps-wrap {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 25px 60px 25px;

  .line:last-child {
    display: none;
  }

  .step, .line {
  }

  .line {
    flex: 1;
    height: 2px;
    background: $gray-light;
    margin-top: 24px;
    max-width: 300px;
  }

  .step {
    width: 50px;
    position: relative;

    .icon {
      width: 50px;
      height: 50px;
      border: 2px solid $gray-light;
      border-radius: 100%;
      background: $gray-light;
      text-align: center;

      i, fa-icon {
        display: none;
        color: white;
        line-height: 46px;
        font-size: 24px;
      }
    }

    .text {
      position: absolute;
      width: 100px;
      left: 50%;
      top: 100%;
      text-align: center;
      padding-top: 7px;
      transform: translateX(-50%);
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
    }

    &.done {
      .text {
        color: $primary;
      }

      .icon {
        background: $primary;
        border-color: $primary;

        .fa-check {
          display: block;
          color: white;
        }
      }

      + .line {
        background: $primary
      }
    }

    &.active {
      .text {
        color: $primary;
      }

      .icon {
        background: white;
        border-color: $primary;

        .fa-ellipsis-h {
          display: block;
          color: $primary;
        }
      }
    }
  }
}
/* HTTP status page */
.http-status {
  .row {
    margin-top: 10vh;
  }

  .status-number {
    font-size: 190px;
    line-height: 120px;
    margin-bottom: 45px;
    font-weight: bold;
  }

  h1 {
    text-transform: uppercase;
    font-size: 36px;
  }

  p {
    word-wrap: break-word;
  }

  @include media-breakpoint-down(sm) {
    .status-number {
      font-size: 140px;
      line-height: 60px;
    }

    h1 {
      font-size: 20px;
    }
  }
}
// SIDE BAR TOGGLE
// collapse sidebar narrower for smaller screens
@media (max-width: 1260px) {
  .side-nav {
    width: $sidenav-collapsed-width;
    top: $header-height;

    &:hover {
      + .subnav {
        width: $subnav-width;
      }
    }
  }

  .subnav {
    top: $header-height;
    width: 10px;
    background: darken($dashboard-header-bg, 15%);

    &:before {
      display: block;
      content: '';
      height: 20px;
      width: 3px;
      position: absolute;
      top: 40%;
      right: 3px;
      margin-top: -10px;
      background: rgba(0,0,0,0.4);
    }

    ul {
      overflow: hidden;
      height: calc(100vh - 80px); // $navbar-height
      .subnav-title {
        height: $sidenav-item-height;
      }
    }

    &:hover {
      width: $subnav-width;
      background-color: $dashboard-header-bg;
    }
  }

  .inner-container.with-sidenav {
    margin-left: $sidenav-collapsed-width;
  }

  .dashboard-header {
    .logo-bar {
      width: 100%;
      background: transparent;
      border-left: $sidenav-collapsed-width solid $gray-lightest;
      height: $header-height;

      .navbar-toggle {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:hover, &:focus {
          background: $secondary;
          color: white;
        }
      }

      .navbar-brand {
        position: relative;
        display: inline-block;
        height: $header-height;
        overflow: hidden;

        img {
          opacity: 0;
        }

        &:before {
          content: '';
          display: block;
          width: 69.7px;
          height: 47px;
          background: url(../assets/images/munio-logo.svg) no-repeat;
          background-size: auto 47px;
        }
      }
    }
  }
}

@media (max-width: 1260px) {
  .dashboard-header {
    .navbar-brand {
      float: right;
    }
  }
}

.dashboard-header {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

// hide sidebar off screen for mobile
@include media-breakpoint-down(sm) {
  .side-nav {
    width: $sidenav-width !important;
    left: -$sidenav-width !important;
  }

  .subnav {
    left: -220px;
    width: $subnav-width;

    &:before {
      display: none;
    }
  }

  .inner-container.with-sidenav {
    margin-left: 0;

    .in-header-title {
      position: relative;
      height: inherit;
      line-height: inherit;
      margin-bottom: $inner-spacing-mobile;
      z-index: auto;
    }
  }
}

.sidenav-active {
  @media (max-width: 1260px) {
    .side-nav {
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
    }

    .subnav {
      width: $subnav-width;
      background-color: $dashboard-header-bg;
    }
  }

  @include media-breakpoint-down(sm) {
    .side-nav {
      left: 0 !important;
    }

    .subnav {
      left: $sidenav-collapsed-width;
    }
  }

  .navbar-toggle {
    i:before {
      content: "\f00d";
    }
  }
}

.mat-dialog-container {
  max-width: 60rem !important;
  position: relative;
}

.cdk-overlay-pane {
  display: flex;
  justify-content: center;
}

/* Log in Dialog */
.login-dialog-container .mat-dialog-container {
  @media (min-width: 768px) {
    min-width: 420px;

    .account-email {
      font-size: large;
    }
  }

  .profile-pic {
    margin-bottom: 1.5em;
  }

  .account-email {
    font-weight: bold;
  }

  .alert {
    text-align: center;
  }
}

.table th, .table td {
  vertical-align: middle;
}

.text-gray-darker {
  color: $gray-darker;
}

.text-gray {
  color: $gray;
}

.text-gray-light {
  color: $gray-light;
}

.strength-meter {
  background: $gray;
}

.flush {
  padding: 0px !important;
  margin: 0px !important;
}

.plush {
  padding: 20px;
}

.plush-half {
  padding: 10px;
}

.spaced {
  margin: 20px;
}

.spaced-half {
  margin: 10px;
}

.card {

  hr {
    border: 1px solid $gray-lighter;
  }

  .header {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.stretch {
  width: 100%;
}

.text-green {
  color: #09b39c !important;
}

.text-red {
  color: #ff7979 !important;
}

.pad-top-extra {
  padding-top: 5px;
}

.card {
  hr {
    border-top: none;
    border-bottom: solid 1px $gray-light;
  }
}

markdown {
  p {
    margin-bottom: 0.75rem;
  }

  p:last-child {
    margin-bottom: 0;
  }


  ul {
    margin-block-start: 0.5rem;
    padding-inline-start: 15px;
  }

  h6 {
    font-size: 0.7rem;
    color: $black;
    margin-bottom: 0;
  }
}

.card-header {
  min-height: 4.5rem;
  border-bottom: solid 1px $gray-light;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  margin: 0 1.5rem;

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-bottom: 0;
  }

  button, .mat-flat-button {
    padding: 5px 20px;
    min-width: 200px;
    text-decoration: none;
    margin-left: auto; // Header buttons float right...
    :hover {
      text-decoration: none;
    }
  }

  .button-replacement-text {
    padding: 5px 20px;
    margin-left: auto; // float right...
    font-weight: 700;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
}

.preserve-whitespace {
  white-space: pre;
}

.agm-map-container-inner {
  height: 100% !important;
}

.alert::before {
  content: "";
  width: 5px;
  height: calc(100% + 2px);
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 4px 0 0 4px;
}

@each $color, $value in $theme-colors {
  .alert-#{$color}::before {
    background-color: red;
  }

  .alert-#{$color} a {
    color: red;
    text-decoration: underline;
  }
}

.stepper-login {
  .mat-horizontal-stepper-header-container {
    width: 120px;
  }
}

.stepper-login-sandbox {
  .mat-horizontal-stepper-header-container {
    width: 80px;
  }
}

.stepper-register {
  .mat-horizontal-stepper-header-container {
    width: 200px;
  }
}

.stepper-disp-register {
  .mat-horizontal-stepper-header-container {
    width: 160px;
  }
}

.stepper-no-text {
  .mat-horizontal-content-container {
    align-items: center;
    overflow: hidden;
    padding: 0;
  }

  .mat-horizontal-stepper-header-container {
    margin: auto;
  }

  .mat-horizontal-stepper-header {
    background: none;
    box-shadow: none;
    width: 40px;
    height: 4px;
    border-radius: 8px;
    padding: 0;

    .mat-step-icon {
      background-color: $gray-lighter;
      border-width: 0;
      width: 35px;
    }

    .mat-step-icon-selected {
      background-color: $primary;
    }

    .mat-step-icon-state-edit {
      background-color: $primary;
    }

    .ng-star-inserted {
      display: none;
    }
  }

  .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background: none !important;
  }


  .mat-stepper-horizontal-line {
    display: none;
  }
}

.back-button {
  color: $primary;
  font-size: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover, &:focus {
    background-color: transparent;
  }
}

.primary-radio-group {
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;

  :hover {
    text-shadow: 1px 0px #BBB;
    transition: all .2s ease;
  }

  .mat-radio-button {
    margin-bottom: 30px;
  }

  .mat-radio-label-content {
    padding-left: 20px;
  }

  .description {
    color: $body-color;
  }
}

.alert {
  .form-group {
    margin-bottom: 0;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.pre-auth-content .form-group {
  margin-bottom: 0.5rem;
}

.progress-bar-gray .mat-progress-bar-buffer {
  background-color: $gray-lighter !important;
}

.font-size-base {
  font-size: 1rem;
}


ol {
  margin-block-start: 0;
  margin-block-end: 1em;
  padding-inline-start: 16px;
}

.deposit-stepper {
  background: none;

  .mat-horizontal-stepper-header {
    background: white;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 20px;
  }

  .mat-dialog-actions {
    margin-top: 50px;
  }
}

.card-deposit-stepper {
  background: none;
  align-content: center;

  .mat-horizontal-stepper-header {
    background: none;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 20px;
  }

  .mat-dialog-actions {
    margin-top: 50px;
  }
}

.preauth-stepper {
  background: none;

  .mat-horizontal-stepper-header {
    background: white;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 20px;
  }

  .mat-dialog-actions {
    margin-top: 50px;
  }
}

.invest-product-modal {
  padding: 0;
  max-width: 800px;

  @include media-breakpoint-up(lg) {
    min-width: 700px;
  }

  .primary-radio-group {
    width: 100%;
    margin-bottom: 20px;

    :hover {
      text-shadow: 0px 0px #BBB;
      transition: all .2s ease;
    }

    .mat-radio-button {
      width: 100%;
      margin-bottom: 0;
      border-bottom: solid 1px $gray-light;
    }

    .mat-radio-label-content {
      padding-left: 20px;
    }

    .mat-radio-label {
      padding: 20px;
      margin-bottom: 0;
    }
  }
}

.primary-border-left::before {
  content: "";
  width: 7px;
  height: calc(100% + 2px);
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 4px 0 0 4px;
  background-color: $primary;
}

.rtl {
  direction: rtl;
}

.filter-wrap {
  button {
    font-size: 12px;
    line-height: 30px;
  }
}

.search-fields {
  .mat-form-field {
    width: auto;
  }
}

.dropdown-toggle.hide-dropdown-icon::after {
  display: none;
}

iframe {
  width: 100%;
  height: 200px
}

.introjs-helperNumberLayer {
  background: $primary;
}

.introjs-helperLayer {
  background-color: rgba(255,255,255,.3);
}

.introjs-progressbar {
  background-color: $primary;
}

.introjs-tooltip {
  background-color: $secondary
}

.introjs-arrow {
  border: 10px solid #fff;
}

.introjs-arrow.top, .introjs-arrow.top-middle, .introjs-arrow.top-right {
  border-color: transparent transparent $secondary;
}

.introjs-arrow.bottom, .introjs-arrow.bottom-middle, .introjs-arrow.bottom-right {
  border-color: $secondary transparent transparent;
}


.introjs-arrow.left, .introjs-arrow.left-bottom {
  border-color: transparent $secondary transparent transparent;
}

.introjs-arrow.right, .introjs-arrow.right-bottom {
  border-color: transparent transparent transparent $secondary;
}

.show-not-tablet {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.show-desktop-only {
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.show-mobile-only {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}

.show-tablet-only {
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}


@include media-breakpoint-down(xs) {
  .investor-table {

    .mat-table {
      display: block;

      .mat-header-row {
        display: none;
      }

      tbody {
        display: block;

        .mat-row {
          height: auto !important;
          display: block;
          padding-left: 10px;


          &:nth-of-type(even) {
            background-color: $gray-light;
          }

          .mat-cell {
            display: block;
            border-bottom-width: 0px;

            &:first-of-type {
              padding-left: 15px;
            }

            &:last-of-type {
              padding-right: 15px !important;
            }

            .cell-value {
              text-align: right;

              .mat-chip-list-wrapper {
                display: inherit !important;
              }
            }

            .cell-key {
              text-align: left;
            }
          }
        }
      }
    }
  }

  .mobile-table-view {

    .mobile-table-row {
        padding-top: 5px;
        padding-bottom: 5px;

        .mobile-table-key {
            font-weight: bold;
        }

        .mobile-table-value {
            text-align: right;
        }
    }
  }

  .earnings-schedule {

    .ngx-charts {
      width: 100%;
    }

    .chart-legend {
      div {
        width: 100% !important;
      }
    }
  }
}


.swiper-button-prev {
  &:focus {
    outline: none;
    }

    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 54 54' style='enable-background:new 0 0 54 54;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath style='fill:%23f5f7f8;' d='M27,1L27,1c14.359,0,26,11.641,26,26v0c0,14.359-11.641,26-26,26h0C12.641,53,1,41.359,1,27v0 C1,12.641,12.641,1,27,1z'/%3E%3Cpath style='fill:%23f0f0f0;' d='M27,54C12.112,54,0,41.888,0,27S12.112,0,27,0s27,12.112,27,27S41.888,54,27,54z M27,2 C13.215,2,2,13.215,2,27s11.215,25,25,25s25-11.215,25-25S40.785,2,27,2z'/%3E%3C/g%3E%3Cpath style='fill:%233e586d;' d='M31.706,40c-0.256,0-0.512-0.098-0.707-0.293L19.501,28.209c-0.667-0.667-0.667-1.751,0-2.418 l11.498-11.498c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L21.12,27l11.293,11.293c0.391,0.391,0.391,1.023,0,1.414 C32.218,39.902,31.962,40,31.706,40z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A") !important;
  }

.swiper-button-next {
  &:focus {
    outline: none;
  }

  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 54 54' style='enable-background:new 0 0 54 54;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath style='fill:%23f5f7f8;' d='M27,53L27,53C12.641,53,1,41.359,1,27v0C1,12.641,12.641,1,27,1h0c14.359,0,26,11.641,26,26v0 C53,41.359,41.359,53,27,53z'/%3E%3Cpath style='fill:%23f0f0f0;' d='M27,54C12.112,54,0,41.888,0,27S12.112,0,27,0s27,12.112,27,27S41.888,54,27,54z M27,2 C13.215,2,2,13.215,2,27s11.215,25,25,25s25-11.215,25-25S40.785,2,27,2z'/%3E%3C/g%3E%3Cpath style='fill:%233e586d;' d='M22.294,40c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L32.88,27 L21.587,15.707c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l11.498,11.498c0.667,0.667,0.667,1.751,0,2.418 L23.001,39.707C22.806,39.902,22.55,40,22.294,40z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-track {
    background: $gray-lighter;
    border-radius: 1em;
  }

  &::-webkit-scrollbar-thumb {
    background: $gb-secondary;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $gb-lightblue;
  }
}

.side-nav.active {
  background-color: $gb-lightblue !important;
}

