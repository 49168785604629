.menu-icon {
  width: 20px;
  height: 20px;
}

li {
  .icon-dashboard {
    background: url(../assets/icons/dashboard/dashboard-inactive.svg) no-repeat;
  }

  .icon-projects {
    background: url(../assets/icons/projects/projects-inactive.svg) no-repeat;
  }

  .icon-portfolio {
    background: url(../assets/icons/portfolio/portfolio-inactive.svg) no-repeat;
  }

  .icon-wallet {
    background: url(../assets/icons/wallet/wallet-inactive.svg) no-repeat;
  }

  .icon-marketplace {
    background: url(../assets/icons/marketplace/marketplace-inactive.svg) no-repeat;
  }
}

li.active {
  .icon-dashboard {
    background: url(../assets/icons/dashboard/dashboard-active.svg) no-repeat;
  }

  .icon-projects {
    background: url(../assets/icons/projects/projects-active.svg) no-repeat;
  }

  .icon-portfolio {
    background: url(../assets/icons/portfolio/portfolio-active.svg) no-repeat;
  }

  .icon-wallet {
    background: url(../assets/icons/wallet/wallet-active.svg) no-repeat;
  }

  .icon-marketplace {
    background: url(../assets/icons/marketplace/marketplace-active.svg) no-repeat;
  }
}
