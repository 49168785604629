@import "./../scss/_bootstrap_overwrites.scss";

.mat-formcontrol {
  width: 100%
}

button, .btn { outline: none !important;}

.input-group-append {
  .btn {
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
    box-shadow: none !important;
    height: $input-height;
    width: $input-height;

    i, fa-icon {
      position: absolute;
      font-size: 1rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
// custom styling overwrite for contact number dropdown plugin
.input-group-addon {
  margin-right: -1px;
  display: flex;
  .dropdown {
    background-color: transparent;
    display: flex;
    align-items: center;
    .dropbtn {
      padding:0;
      height: $input-height;
      width: $input-height;
      background: $gray-light;
      border-radius: $input-border-radius 0 0 $input-border-radius;
      box-shadow: none !important;
      .defaultCountry {
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: none;
        position: absolute;
        font-size: 20px;
        left: 50%;
        top: 50%;
        color: black;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
        &:before { content: "\f0ac"; } // fa-globe
      }
      &:hover { background-color: darken($gray-light, 10%); }
      &:focus {
        background: $primary;
        span { color: white; }
      }


    }
  }
}

.dropdown-content {
  .list-group-item {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border-top: none !important;
    border-bottom: none !important;
    &:hover {
      background: fade_out($primary, 0.7);
    }
    .country-name { margin-left: 1rem !important;}
  }
}

.dropdown-menu.show + .input-group-append .btn-default {
  background: $primary;
  color: white;
}

.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
  width: 2.5rem !important;
  height: 2.5rem !important;
  line-height: 2.5rem !important;
  &:focus {outline: none !important;}
  >div {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 100%;
  }
}

.ngb-dp-month:first-child .ngb-dp-week {
  padding-top: 0.25rem;
}

.ngb-dp-weekday {
  color: $secondary !important;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
}


input:-webkit-autofill {
  -webkit-box-shadow:inset 0 0 0 1000px #ffffff !important;
  -webkit-text-fill-color: $input-color !important;
}

.field-lg {
  @media (min-width: 768px) {
    .form-control {
      height: $input-height-lg;
    }
  }
}

textarea.form-control {
  line-height: 1.5;
  min-height: 100px;
}

.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  line-height: 0px;
  height: $input-height;
  background-color: transparent;

  &.is-valid {
    border-color: $input-border-color;
  }

  &:focus {
    background-color: transparent;
    box-shadow: 0 0.2rem 0 0 lighten($primary, 55%);
  }

  &.is-valid:focus {
    border-color: $primary !important;
    box-shadow: 0 0.2rem 0 0 lighten($primary, 55%);
  }

  &.is-invalid:focus {
    box-shadow: 0 0.2rem 0 0 lighten($danger, 40%);
  }
}

.form-check {
  margin-left: 1rem;
  margin-right: 1rem;
  .invalid-feedback {
    padding-left: 0;
    padding-right: 0;
  }
}

.form-group label {
  min-height: $label-height;
  margin-bottom: $label-margin-bottom;
  color: $gray;
  font-weight: 500;
  font-size: 0.75rem;

  i, fa-icon {
    &.fa-info-circle {
      color: $info;
      margin-left: 5px;
    }
  }
}

.terms-and-conditions {
  .form-group label {
    margin-bottom: 0;
    color: $secondary;
    font-size: 1rem;
  }
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $body-color;
}

app-field-required-status, .app-field-required-status {
  .fa {
    color: $danger;
    margin: auto;
    font-size: 18px;
  }
}

.status-field-wrap {
  display: block;
  position: relative;
  padding-right: 2rem;

  app-field-required-status, .app-field-required-status {
    position: absolute;
    top: $label-height+$label-margin-bottom;
    right: 0;
    display: block;
    color: $danger;

    .icon-wrap {
      display: flex;
      width: 32px;
      height: 44px;
    }
  }

  &.no-label {
    app-field-required-status, .app-field-required-status {
      top: 0;
    }
  }
}

.table {
  .app-field-required-status {
    margin-right: 0.5rem;
  }
}

.form-buttons-right {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;

  .btn {
    min-width: 160px;
  }
}

textarea.form-control {
  min-height: 130px;
}

int-phone-prefix.ng-invalid.ng-touched .form-control {
  border-color: $danger;
  border-left-color: transparent;

  &:focus {
    box-shadow: 0 0.2rem 0 0 lighten($danger, 40%);
    border-left-color: $danger;
  }
}

.field-content-projection.is-invalid {
  border: 1px solid $danger !important;
  border-radius: 3px;
}

.form-check {
  padding: 0;
  margin-left: 1rem;
  margin-right: 1rem;

  .form-check-label {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 28px;
    padding-left: 30px;

    &:not([disabled]) {
      cursor: pointer;
    }

    .form-check-input {
      width: 22px;
      height: 22px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 0;
      visibility: hidden;
      transform: translateY(-50%);
      margin: 0;

      &:checked ~ .check-icon {
        background: $primary;
        border-color: $primary;

        i, fa-icon {
          display: block;
        }
      }

      &:disabled ~ .check-icon {
        opacity: 0.4;
        cursor: auto;
      }
    }

    .check-icon {
      cursor: pointer;
      width: 22px;
      height: 22px;
      border: 1px solid $gray-light;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      i, fa-icon {
        line-height: 20px;
        font-size: 14px;
        color: white;
        display: none;
      }
    }
  }

  .invalid-feedback {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
}

.popover.filters {
  min-width: 240px;
  font-size: $font-size-base;
  z-index: 1025;

  .popover-body {
    .form-group:last-child {
      margin-bottom: 0.5rem;
    }
  }
}



.ng-select {
  .ng-select-container {
    color: $input-color;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: $input-height;
    padding-right: $input-btn-padding-x;
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: $primary!important;
      box-shadow: 0 0.2rem 0 0 lighten($primary, 55%);
      background: transparent;
    }

    &:not(.ng-select-opened) > .ng-select-container {
      box-shadow: 0 0.2rem 0 0 lighten($primary, 55%)
    }
  }

  &.ng-select-disabled {
    opacity: 0.5;
  }

  &.ng-select-single .ng-select-container {
    height: $input-height;
    background: transparent;

    .ng-value-container {
      padding-left: 0;

      .ng-input, .ng-value {
        position: absolute;
        padding: $input-btn-padding-y 0;
        padding-right: 60px;
        top: 0;
        left: 0;
      }

      .ng-input input {
        color: $input-color;
      }

      .ng-value {
        width: 100%;
        padding-right: 65px;
      }
    }
  }

  .ng-clear-wrapper {
    .ng-clear {
      font-size: 28px !important;
    }
  }

  .ng-arrow-wrapper {
    padding-right: 0;
    margin-right: -10px;
    height: 20px;

    .ng-arrow {
      border: none;
      color: $body-color;
      display: inline-block;
      width: auto !important;
      height: auto !important;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &:before {
        content: "\f078";
      }
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow-wrapper {
    transform: rotate(180deg);
    top: 0;
  }

  &.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-bottom-right-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
  }

  &.ng-select-opened.ng-select-top > .ng-select-container {
    border-top-right-radius: $input-border-radius;
    border-top-left-radius: $input-border-radius;
  }

  &.is-invalid .ng-select-container {
    border-color: $danger;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: fade_out($primary, 0.9);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: fade_out($primary, 0.7);
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 2px;
}

.ng-select.is-invalid ~ .invalid-feedback {
  display: block;
}

.simplemde {
  display: block;

  pre {
    color: $input-color;
  }

  .editor-toolbar.disabled-for-preview a:not(.no-disable) {
    background: none;
    opacity: 0.6;
  }

  .editor-toolbar {
    opacity: 1;
    border-color: $input-border-color;
    background: #f7f7f7;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &:hover {
      opacity: 1;
    }

    &.fullscreen {
      z-index: 1100;
      top: $header-height;
    }

    a {
      color: $body-color !important;
    }
  }

  .editor-preview-side {
    top: $header-height + 50px;
  }

  .editor-preview-side, .editor-preview {
    h1 {
      font-size: $font-size-lg;
      margin-bottom: 1rem;
    }
  }

  .CodeMirror-fullscreen {
    z-index: 1100;
    top: $header-height + 50px;
  }

  .CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
    background: transparent;
  }

  .CodeMirror {
    color: $body-color;
    border-color: $input-border-color;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .editor-statusbar {
    border-color: $input-border-color;
  }

  &.is-invalid {
    .editor-toolbar, .CodeMirror, .editor-statusbar {
      border-color: $danger;
    }

    .CodeMirror {
      border-top: none;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #cbcbcb;
      }
    }
  }
}

.simplemde.is-invalid ~ .invalid-feedback {
  display: block;
  margin-top: -1.6rem;
  margin-bottom: 1.5rem;
}

.sebm-google-map-container {
  display: block;
  border: 1px solid $input-border-color;
  border-radius: 8px;
  overflow-y: hidden;
}

.wide-screen-filter,
.popover.filters {
  .ng-select {
    .ng-select-container {
      padding: $input-btn-padding-y-sm;
      border-radius: $input-border-radius;
      height: 35px;
    }

    &.ng-select-single .ng-select-container {
      .ng-value-container {
        .ng-input, .ng-value {
          padding: 0.54rem 1rem;
        }

        .ng-input input {
          font-size: 13px;
          line-height: 1.3
        }

        .ng-value {
          font-size: 13px;
          line-height: 1.3
        }
      }
    }

    .ng-arrow-wrapper {
      margin-right: -6px;
      width: 22px;
      height: 23px;

      .ng-arrow {
        font-size: 11px;
      }
    }

    .ng-clear-wrapper {
      width: 10px;

      .ng-clear {
        font-size: 20px !important;
        line-height: 1.5 !important;
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
  }

  .form-group {
    margin-bottom: 0.5rem;
    width: 100%;

    label {
      font-size: 12px;
    }
  }
}

.recaptcha {
  width: 304px;
  height: 78px;
  margin: auto;
  position: relative;

  re-captcha {
    position: relative;
  }

  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    right: 2px;
    left: 0;
    bottom: 2px;
    border: 1px solid lightgrey;
    border-radius: 3px;
  }

  @media (max-width: 374px) {
    transform: scale(0.83);
    transform-origin: left;
  }
}

.loading-blocks {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 300px;
    bottom: 0;
    animation: shadow-shimmer 2s infinite;
    -webkit-animation: shadow-shimmer 2s infinite;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 44%, rgba(255, 255, 255, 0.5) 56%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 44%, rgba(255, 255, 255, 0.5) 56%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 44%, rgba(255, 255, 255, 0.5) 56%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  }
}

@keyframes shadow-shimmer {
  0% {
    left: -50%;
  }

  100% {
    left: 120%;
  }
}

@-webkit-keyframes shadow-shimmer {
  0% {
    left: -50%;
  }

  100% {
    left: 120%;
  }
}

.shadow-checkbox {
  height: 28px;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 30px;

  &:before, &:after {
    content: '';
    display: block;
    background: rgba(0,0,0,0.06);
  }

  &:before {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
    top: 3px;
  }

  &:after {
    height: 12px;
    width: 125px;
    position: relative;
    top: 8px;
  }
}

.shadow-field, .shadow-btn {
  width: 100%;
  height: 43.59px;
  margin: 0 auto $form-group-margin-bottom auto;
  border-radius: $input-border-radius;
  background: $gray-lighter;

  @include media-breakpoint-up(sm) {
    &.shadow-field-lg {
      height: $input-height-lg;
      border-radius: $input-border-radius-lg;
    }

    &.shadow-btn-lg {
      height: $input-height-lg;
      border-radius: $btn-border-radius-lg;
    }
  }
}

.shadow-btn {
  background: $primary;
  opacity: 0.25;
  margin-top: 2rem;
  border-radius: $btn-border-radius;
}

.shadow-captcha {
  width: 304px;
  height: 78px;
  margin: 0 auto 1rem auto;
  background: $gray-lighter;

  @media (max-width: 374px) {
    transform: scale(0.83);
    transform-origin: left;
  }
}

.shadow-page-header {
  height: 72.78px;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .shadow-breadcrumbs {
    height: 15px;
    opacity: 0.8;

    i, fa-icon {
      margin-left: 10px;
      margin-right: 5px;
      color: $gray-lighter;
    }

    &:before, &:after {
      display: inline-block;
      content: '';
      height: 12px;
      width: 70px;
      background: $gray-lighter;
    }
  }

  .shadow-page-title {
    height: 22px;
    width: 250px;
    background: #f0f0f0;
    margin-top: 1rem;
  }

  @media (min-width: 992px) {
    margin-top: -1.2rem;
  }
}

.shadow-h3 {
  background: #f0f0f0;
  width: 250px;
  height: $h3-font-size;
  margin-bottom: $headings-margin-bottom;
}

.shadow-table-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .shadow-title {
    height: 15px;
    flex: 1;

    &:before {
      content: '';
      display: inline-block;
      width: 50%;
      background: #f0f0f0;
      height: 15px;
    }
  }
}

.shadow-table-row {
  width: 100%;
  height: 35px;
  margin: 0 auto 0.2rem auto;
  background: $gray-lighter;
}


.drop-container {
  width: 100%;
  margin: 1rem auto;
  border: 2px dashed $gray-light;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &.is-drop-over {
    background: fade_out($primary, 0.9);
    border-color: $primary;
    color: $primary;
  }

  .upload-button {
    margin: 1rem;

    input {
      display: none;
    }
  }
}

.in-form-file-upload {
  .drop-container {
    margin-top: unset;
    margin-bottom: 0.5rem;
  }
}


img-cropper {
  margin-left: -1rem;
  margin-right: -1rem;
  display: block;

  canvas {
    width: 100% !important;
  }

  @media (min-width: 992px) {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}






