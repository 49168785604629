//Munio vars

$green: #4FD6B5;
$red: darken(#ff7979, 5%);
$black: #212b34;
$blue: #4170d7;
$bank-id-blue: #469CBE;
// Bootstrap vars
$warning: orange;

$gray: #b2bcc4;
$gray-light: #e4ebf0;
$gray-lighter: #eef3f6;
$gray-darker: #3e586d;
$gray-lightest: #f5f7f8;

$primary: #004D40; //#34bfc0;
$primary-text-emphasis-dark: #004D40;
$secondary: #00877B;
$danger: $red;
$info: $gray;

$home-blue: #0F1740;
$home-gray: #EDF2F6;
$home-lightblue: #F3F3F5;

$gb-primary: #004D40;
$gb-secondary: #00877B;
$gb-lightblue: #1DE7B4;
$gb-lightgreen: #004D40;

$gb-heading-font: "DM Serif Display", serif;
$gb-subheading-font: "Montserrat Bold", sans-serif;
$gb-body-font: "Montserrat Light", sans-serif;

$body-color: $gray-darker;
$body-bg: #ECEFF1;
$font-family-sans-serif: 'Avenir', sans-serif;
$font-size-base: 0.875rem;
$line-height-base: 1.2;

$h1-font-size: $font-size-base * 2.63;
$h3-font-size: 1.375em;

$headings-font-weight: 700;
$headings-margin-bottom: 1.3rem;
$paragraph-margin-bottom: 1.5rem;

$small-font-size: 90%;

$navbar-padding-y: 0.688em;
$navbar-light-color: $black;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $black;
$navbar-nav-link-padding-x: 1.2rem;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: transparent;

$btn-border-radius: 0px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-padding-x-lg:  2rem;
$btn-padding-x: 1.1rem;
$btn-padding-y: 0.8rem;
$btn-padding-y-lg: 1.1rem;
$btn-padding-y-sm: 0.6rem;


$label-height: 21px;
$label-margin-bottom: 8px;
$form-group-margin-bottom: 1.5rem;



$input-btn-padding-x-lg: 3rem;

$input-btn-padding-x-sm: 1.5rem;


$input-btn-line-height: 1;    // IE11 not playing ball with line-height computing > 1
$input-btn-line-height-lg: 1; // IE11 not playing ball with line-height computing > 1
$input-btn-line-height-sm: 1; // IE11 not playing ball with line-height computing > 1

$input-bg:  white;
$input-color: $black;
$input-placeholder-color: $gray;
$input-border-color: rgba(0,0,0,0.2);
$input-border-width: 1px;
$input-padding-x:  0;
$input-padding-x-lg:  0;

$input-focus-border-color: $primary;
$form-feedback-valid-color: $input-border-color;


$btn-font-weight: 700;
$btn-border-width:  0;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$card-border-radius: 0;

$modal-header-border-color: transparent;

$modal-content-border-color: $gray-lighter;
$modal-content-border-width:  2em;
$modal-content-border-radius:  0;
$modal-backdrop-opacity: .8;
$modal-inner-padding: 2rem;
$modal-header-padding: 2rem;

$modal-sm:  500px;
$modal-md:  750px;
$modal-lg:  1140px;

$progress-height:   5px;
$progress-bg: $gray-light;

$table-head-bg: $gray-light;
$table-cell-padding: 1.2rem 1rem;
$table-cell-padding-sm:  .6rem 1rem;

$badge-padding-x: 0.45em;
$badge-padding-y: 0.5em;
$badge-border-radius: 15px;

$popover-body-padding-y: 1rem;

$zindex-modal-backdrop:             1200 !default;
$zindex-modal:                      1250 !default;

$card-border-width: 0;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-cap-bg: white;


$sidenav-width: 300px;
$sidenav-collapsed-width: 80px;
$sidenav-item-height: 75px;

$subnav-width: $sidenav-width - $sidenav-collapsed-width;
$header-height: 80px;

$inner-spacing: 2rem;
$inner-spacing-mobile: 1rem;

//
$primary-text-emphasis-dark: $primary;
$secondary-text-emphasis-dark: $gray-darker;
$success-text-emphasis-dark: $green;
$warning-text-emphasis-dark: darken(#ff7979, 50%);
$danger-text-emphasis-dark: darken(#ff7979, 50%);
$light-text-emphasis-dark: $gray-lighter;
$dark-text-emphasis-dark: $gray-darker;
$primary-bg-subtle-dark: $gray-darker;
$secondary-bg-subtle-dark: $gray-darker;
$success-bg-subtle-dark: $green;
$info-bg-subtle-dark: $gray-darker;
$warning-bg-subtle-dark: $gray-darker;
$danger-bg-subtle-dark: $gray-darker;
$light-bg-subtle-dark: $gray-darker;
$dark-bg-subtle-dark: $gray-darker;
$primary-border-subtle-dark: $gray-darker;
$secondary-border-subtle-dark: $gray-darker;
$success-border-subtle-dark:  $green;
$info-text-emphasis-dark: $gray-darker;
$info-border-subtle-dark: $gray-darker;
$warning-border-subtle-dark: $gray-darker;
$danger-border-subtle-dark: $gray-darker;
$light-border-subtle-dark: $gray-darker;
$dark-border-subtle-dark: $gray-darker;
