app-admin-sidenav {
  display: block;
  position: relative;
}

.side-nav {
  position: fixed;
  top: $header-height + 40px;
  left: 0;
  z-index: 1020; // lower than 1030 header
  width: $sidenav-width;
  height: 100%;
  background: white;
  overflow-y: auto;
  transition: all 300ms ease-in-out;

  ul {
    margin: 0;
    padding: 0;
    height: calc(100vh - 80px); //$header-height
    list-style: none;
    width: $sidenav-width;
    overflow-x: hidden;

    li {
      position: relative;
      display: block;
      height: $sidenav-item-height;

      a {
        height: $sidenav-item-height;
        line-height: 1.5rem;
        cursor: pointer;
        color: $gray-darker;
        text-decoration: none;
        padding: 1.7rem 1rem 1.7rem $sidenav-collapsed-width;
        display: block;
        text-transform: uppercase;
        position: relative;
        transition: all 0.2s ease-in-out;

        &:hover, &:focus {
          background: rgba(0,0,0,0.02);
        }

        img, .profile-pic, svg, i {
          position: absolute;
          left: $sidenav-collapsed-width/2;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: left 300ms ease-in-out;
          fill: $secondary;
        }

        &.profile-link {
          .profile-pic {
            width: 60px;
            height: 60px;

            i, fa-icon {
              font-size: 56px;
              line-height: 60px;
            }
          }

          .user-name {
            font-weight: normal;
            text-transform: capitalize;
            display: block;
            font-size: 1rem;
            color: black;
          }
        }
      }

      &.active {
        background: lighten($primary, 60%);

        svg {
          fill: $primary;
        }

        a {
          font-weight: 700;
          color: $primary;
          border-right: 3px solid $primary;
        }
      }
    }
  }
}




.subnav {
  background-color: white;
  position: fixed;
  top: $header-height + 40px;
  left: $sidenav-collapsed-width;
  width: $subnav-width;
  height: 100%;
  bottom: 0;
  transition: all 300ms ease-in-out;
  overflow-x: hidden;
  z-index: 1040; // higher than sidebar and header
  ul {
    margin: 0;
    padding: 0;
    height: 100vh; //$header-height
    list-style: none;
    width: $subnav-width - 1px;

    li {
      position: relative;
      display: block;
      height: $sidenav-item-height;

      &:not(.subnav-title) a {
        height: $sidenav-item-height;
        padding: 1.7rem 1rem 1.7rem 1.3rem;
        color: $body-color;
      }

      a {
        display: block;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        font-weight: 500;

        &:hover, &:focus {
          background: rgba(0,0,0,0.02);
        }

        &.active {
          background: lighten($primary, 60%);
        }

        &:before {
          content: '';
          display: block;
          border-style: solid;
          border-width: 6px 8px;
          border-color: transparent transparent transparent $primary;
          position: absolute;
          left: 1.3rem;
          top: 50%;
          margin-top: -8px;
          opacity: 0;
          transition: opacity 0.2s 0.2s ease-in-out;
        }
      }

      &.subnav-title a {
        &:before {
          margin-top: -7px;
        }
      }

      &.active a {
        color: $primary;
        background: lighten($primary, 60%);
        padding-left: 2.1rem;

        &:before {
          opacity: 1;
        }
      }
    }

    .subnav-title {
      height: $header-height;

      a {
        height: $header-height;
        color: $gray-900;
        font-size: 18px;
        line-height: $header-height;
        padding-left: 1.3rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      @media (max-width: 1260px) {
        height: $sidenav-item-height;

        a {
          height: $sidenav-item-height;
          line-height: $sidenav-item-height;
          border-bottom: none;
        }
      }
    }
  }
}


