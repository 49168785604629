@import "bootstrap_overwrites";

.modal {
  text-align: center;
}

.modal-dialog {
  max-width: none !important;
  text-align: left;
  display: inline-block;
}

.mat-dialog-title {
  text-transform: uppercase;
  font-weight: bold;
  color: $primary;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
  align-items: center;
  padding: 1rem;
  .modal-title {
    h3 {
      font-weight: 400;
      margin-bottom: 0.3rem;
      font-size: 2em;
    }
    .sub-title {
      font-style: italic;
      font-size: 0.875em;
      font-weight: 500;
    }
  }
  .btn { min-width: 160px; }
  .close {
    padding: 0.5em;
    font-size: 2em;
    line-height: 0.5;
  }
}

.modal-body {
  .body-title {
    font-size: 2rem;
    color: #4a4a4a;
    font-weight: 500;
    margin-top: -3.5rem;
    margin-bottom: 1rem;
  }
  &.modal-body-loading {
    min-height: 339px;
  }
}
.modal-footer {
  border-top: none;
  padding-top: 0;
  .btn { min-width: 130px;}
}

.modal-content {
  background-clip: border-box;
  &.no-border {
    border: 1px solid rgba(black, .2);
    .modal-header {
      .close { padding: 1em; }
    }
  }
  @media (min-width: 500px) {
    min-width: 500px;
  }
}

@include media-breakpoint-down(md) {
  .modal-content { border-width: 1em; }
  .modal-body, .modal-header, .modal-footer { padding: 1rem; }
  .modal-body {
    .body-title { margin-top: -1.5em; font-size: 1.5em}
  }
  .modal-header {
    .close { margin: -0.5rem -0.5rem -0.5rem auto; }
  }
}

@include media-breakpoint-down(xs) {
  .modal-header {
    flex-wrap: wrap;
    .btn {
      display: block;
      margin-top: 0.5em;
      margin-bottom: 1em;
      width: 100%;
    }
  }

  .cdk-overlay-pane {
    max-width: none !important;
    height: auto;
    //width: 100vw !important;
    overflow: auto;
    justify-content: center;
  }
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  .image {
    flex: 1;
    min-width: 250px;
    max-width: 750px;
    overflow: hidden;
    border: 5px solid white;
    .img-wrap {
      position: relative;
      border: 1px solid #979797;
      height: 0;
      padding-bottom: 53%;
      background-size: cover;
      background-color: #808080;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}


.modal {
  &.show .modal-dialog {
    transition: 0.25s all;
    opacity: 0;
    transform: translate(0, 5vh);
  }

  &.custom-show .modal-dialog {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.modal-backdrop {
  &.show {
    transition: 0.25s all;
    opacity: 0;
  }

  &.custom-show {
    opacity: 0.8;
  }
}

.mat-dialog-actions {
  padding-bottom: 1rem !important;

  button, .btn {
    min-width: 150px;
    margin: 8px;
  }

  .btn:focus, .btn.focus {
    box-shadow: unset;
  }
}

.cdk-overlay-container {
  z-index: 1200 !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,0.32);
}

.modal-instructions-alert {
  max-width: 500px;
}

.modal-9 {
  z-index: 9000 !important;
}

.modal-backdrop-9 {
  z-index: 8999 !important;
}
