@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './bootstrap_overwrites';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-typography-config( $font-family: 'Avenir' );
@include mat.core($custom-typography);

$primary-palette: ( 50 : #f1e5ef, 100 : #ddbfd8, 200 : #c795be, 300 : #b06aa3, 400 : #9f4a90, 500 : #00877B, 600 : #862574, 700 : #7b1f69, 800 : #71195f, 900 : #5f0f4c, A100 : #ff95e3, A200 : #ff62d6, A400 : #ff2fc9, A700 : #ff15c2, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #ffffff, A700 : #ffffff, ) );

$secondary-palette: (
    50 : #e8ebed,
    100 : #c5cdd3,
    200 : #9facb6,
    300 : #788a99,
    400 : #5b7183,
    500 : #3e586d,
    600 : #385065,
    700 : #30475a,
    800 : #283d50,
    900 : #1b2d3e,
    A100 : #80beff,
    A200 : #4da3ff,
    A400 : #1a89ff,
    A700 : #017cff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$red-palette: (
    50 : #ffefef,
    100 : #ffd7d7,
    200 : #ffbcbc,
    300 : #ffa1a1,
    400 : #ff8d8d,
    500 : #ff7979,
    600 : #ff7171,
    700 : #ff6666,
    800 : #ff5c5c,
    900 : #ff4949,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff0f0,
    A700 : #ffd6d6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$munio-app-primary: mat.define-palette($primary-palette);
$munio-app-accent:  mat.define-palette($secondary-palette);

// The warn palette is optional (defaults to red).
$munio-app-warn:    mat.define-palette($red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$munio-app-theme: mat.define-light-theme($munio-app-primary, $munio-app-accent, $munio-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($munio-app-theme);

.card {
  border-radius: 4px !important;
  box-shadow: 0 13px 32px 0 rgba(0,0,0,0.07) !important;
}

.mat-button {
  text-transform: uppercase !important;
  border-radius: 0px !important;
  font-weight: 600 !important;
}

.mat-flat-button {
  text-transform: uppercase !important;
  border-radius: 0px !important;
  font-weight: 600 !important;
}

.mat-stroked-button {
  text-transform: uppercase !important;
  border-radius: 0px !important;
  border-width: 2px !important;
  font-weight: 600 !important;
}

.mat-horizontal-content-container {
  padding: 24px !important;
}

table.mat-table {
  width: 100%;
}

.header-mat-dialog {
  .mat-dialog-container {
    background-color: $secondary;
    padding-bottom: 0;
  }

  .mat-dialog-content {
    background-color: white;
    max-height: 90vh;
  }

  .mat-dialog-title {
    font-weight: 600 !important;
    color: white;
    display: flex !important;
    justify-content: space-between;

    .dialog-close {
      font-size: 32px;
      line-height: 32px;
    }

    .mat-button {
      padding: 0;
      min-width: 0;
    }

    .mat-button-focus-overlay {
      background-color: transparent;
    }

    .mat-menu-item:hover {
      background-color: transparent;
    }

    .dialog-text-right {
      margin-left: auto;
    }
  }
}

.mat-form-field {
  font-weight: 600 !important;
  width: 100%;
}

.mat-form-field-label {
  font-size: 0.85rem !important;
}

a.mat-button, a.mat-flat-button, a.mat-stroked-button {
  text-decoration: none;
}

.mat-form-field .mat-form-field-infix {
  width: auto;
}

.mat-horizontal-stepper-header {
  background: white;
  box-shadow: $gray-light 0 0 5px;

  .mat-step-label, .mat-step-label-selected {
    font-weight: bold;
  }

  .mat-step-optional {
    display: none;
  }
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background: white !important;
}

.mat-step-header .mat-step-icon {
  width: 32px;
  height: 32px;
  font-weight: bold;
  border: 2px dotted $gray-darker;
  color: $gray-darker;
  background-color: transparent;

  &.mat-step-icon-selected {
    color: white;
    background-color: $primary;
    border: 2px solid $primary;
  }
}

.mat-dialog-content .mat-dialog-actions {
  app-submit, button, a {
    min-width: 150px;
    line-height: 3rem;
  }

  margin-bottom: 0;
}

.mat-slider-track-wrapper {
  height: 5px !important;
}

.mat-slider-track-fill {
  height: 5px !important
}

.mat-slider-track-background {
  height: 5px !important;
  background-color: $gray-light !important;
}

.mat-slider-horizontal .mat-slider-wrapper::after {
  border-left: 0px !important;
}

.mat-radio-label {
  white-space: normal !important;
  overflow: hidden !important;
  /* Make space for the halo around the actual button... */
  padding-left: 10px;
  min-height: 40px;
}

.mat-sort-header-arrow, [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow {
  margin: 0 6px 0 6px !important;
}

.mat-button, .mat-stroked-button, .mat-solid-button, .mat-flat-button {
  &.small {
    line-height: 24px;
    min-width: 36px;
  }
}

.mat-button-toggle-vertical .mat-button-toggle-label-content {
  display: flex !important;
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background: rgba(0,0,0,0.02);
}

.mat-button-toggle:hover {
  background: rgba(0,0,0,0.02);
}


