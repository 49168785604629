@import "../scss/_bootstrap_overwrites.scss";

.showbox {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  padding: 5%;
  background-color: #eeeeee;
}

.spinner-wrapper {
  display: block;
  position: absolute;
  width: 100%;
  min-height: 200px;
  height: 200px;
  top: 50%;
  margin-top: -100px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -100px;
    animation: outer-rotate 2.91667s linear infinite;

    .inner {
      width: 200px;
      height: 200px;
      position: relative;
      animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;

      .gap {
        position: absolute;
        left: 99px;
        right: 99px;
        top: 0;
        bottom: 0;
        border-top: 10px solid;
        box-sizing: border-box;
      }

      .left,
      .right {
        position: absolute;
        top: 0;
        height: 200px;
        width: 100px;
        overflow: hidden;

        .half-circle {
          position: absolute;
          top: 0;
          width: 200px;
          height: 200px;
          box-sizing: border-box;
          border: 15px solid $primary;
          border-bottom-color: transparent;
          border-radius: 50%;
        }
      }

      .left {
        left: 0;

        .half-circle {
          left: 0;
          border-right-color: transparent;
          animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
          -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
        }
      }

      .right {
        right: 0;

        .half-circle {
          right: 0;
          border-left-color: transparent;
          animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
          -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
        }
      }
    }
  }

  @keyframes outer-rotate {
    0% {
      transform: rotate(0deg) scale(0.5);
    }

    100% {
      transform: rotate(360deg) scale(0.5);
    }
  }

  @keyframes left-wobble {
    0%, 100% {
      transform: rotate(130deg);
    }

    50% {
      transform: rotate(-5deg);
    }
  }

  @keyframes right-wobble {
    0%, 100% {
      transform: rotate(-130deg);
    }

    50% {
      transform: rotate(5deg);
    }
  }

  @keyframes sporadic-rotate {
    12.5% {
      transform: rotate(135deg);
    }

    25% {
      transform: rotate(270deg);
    }

    37.5% {
      transform: rotate(405deg);
    }

    50% {
      transform: rotate(540deg);
    }

    62.5% {
      transform: rotate(675deg);
    }

    75% {
      transform: rotate(810deg);
    }

    87.5% {
      transform: rotate(945deg);
    }

    100% {
      transform: rotate(1080deg);
    }
  }
}
