@import "bootstrap_overwrites";

.invest-card {
  font-weight: 600;
  
}

.investment-options {
  app-product-card {
    margin-right: 20px;
  }

  app-product-card:last-child {
    margin-right: 0;
  }
}

.token-type-banner {
      background-color: $gray-light;
    position: absolute;
    padding: 7px 15px;
    font-size: 11px;
    border-radius: 0 3px 3px 0;
}

.investment-option {
  width: 300px;
  height: 100%;
  min-height: 500px;
  font-weight: 600;
  padding: 15px;
  background-color: white;
  position: relative;
  border: 1px solid #E4EBF0;
  border-radius: 3px;

  .token-type-banner {
    left: 0;
    top: 15px;
  }

  .token-name-banner {
    background-color: $primary;
    color: white;
    position: absolute;
    left: 0;
    top: 15px;
    padding: 7px 15px;
    font-size: 11px;
    border-radius: 0 3px 3px 0;
  }

  .product-name {
    margin-top: 50px;
    font-size: 15px;
    margin-right: 100px;
    white-space: pre-line;
    min-height: 50px;
  }

  .payment-option-name {
    font-size: 12px;
  }

  .token-balance {
    margin-top: 30px;
    font-size: 15px;
    white-space: pre-line;
  }

  .heading {
    font-size: 0.7rem;
    color: $black;
    margin-top: 0.75rem;
  }

  .value {
    font-weight: 500;
    font-size: 14px;
    color: $gray-darker;
  }

  .availability-chart {
    position: absolute;
    display: inline-block;
    // The ngx-charts control includes a 20px margin already, so we don't need to add extra...
    right: 0;
    top: 0;
    width: 120px;
    height: 120px;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 8px;
      padding: 0.5rem;

      .percentage-label {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .invest-button-placeholder {
    height: 100px;
  }

  .invest-button {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    margin-top: 50px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
